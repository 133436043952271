<template>
  <div class="misc-wrapper">

    <div class="misc-inner">
      <div class="w-100 text-center">
        <h2 class="mb-1">
         {{$t('error.soldoutViews')}}
        </h2>
        <p class="mb-2 avenir-medium">
         {{$t('error.noticeViews')}}
        </p>

        <b-button
          variant="primary"
          class="mb-2 btn-sm-block"
          @click="redirectPackage()"
        >
          {{$t('error.buyPackage')}}
        </b-button>
      </div>
      <div class="w-100 text-center">
        <!-- image -->
        <b-img
          fluid
          :src="downImg"
          alt="Error page"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { BButton, BImg } from 'bootstrap-vue'
export default {
  components: {
    BButton,
    BImg,
  },
  data () {
    return {
      downImg: require('@/assets/images/pages/error.svg')
    }
  },
  methods: {
    redirectPackage () {
      this.$router.push({ name: 'pages-account-setting', params: { section: 'membership', tab: 'packages' } }).catch(() => {})
    },
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
